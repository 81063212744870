import {
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  type Ref,
  unref,
  watchEffect,
} from 'vue'

interface InfiniteScrollParams {
  el: Ref<Element | undefined | null> | Element
  loadMore: () => Promise<void> | undefined
  disabledObserver?: Ref<boolean> | boolean | undefined
  options?: IntersectionObserverInit
}
export function useInfiniteScroll({
  el,
  loadMore,
  disabledObserver,
  options = {},
}: InfiniteScrollParams) {
  const isLoading = ref(false)
  let observer: IntersectionObserver
  const isObserverInited = ref(false)

  function createObserver() {
    observer = new IntersectionObserver(async (entries) => {
      const [{ isIntersecting, target }] = entries
      if (isIntersecting && !isLoading.value && !unref(disabledObserver)) {
        isLoading.value = true
        await loadMore()
        isLoading.value = false
        await nextTick()
        /**
         * Есть кейс когда после подгрузки следующей страницы
         * observer все еще виден и нужно сразу же загружать следующую
         * Но observer не вызывает callback повторно, если его не 'перезагрузить'
         * */
        observer.unobserve(target)
        observer.observe(target)
      }
    }, options)
    isObserverInited.value = true
  }

  onMounted(() => {
    createObserver()
  })

  onBeforeUnmount(() => {
    if (observer) {
      observer.disconnect()
    }
  })

  watchEffect(() => {
    const element = unref(el)
    if (element && isObserverInited.value) {
      observer.observe(element)
    }
  })
}
